import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Document, Page } from "react-pdf";
import styled from "styled-components";

// Define a styled button to match the style of OpenButton
const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: rgb(46, 48, 145);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgb(46, 48, 145);
  }
`;

const PdfModal = ({ open, onClose, pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  // Detect screen size and set isMobile to true if screen is small
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };
    handleResize(); // Call initially to set the state
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to handle when the PDF is loaded
  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "/assets/Brochure-final.pdf"; // Modify this to the desired file name
    link.click();
  };

  const nextPage = () => {
    setPageNumber((prev) => (prev < numPages ? prev + 1 : prev));
  };

  const prevPage = () => {
    setPageNumber((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const openInNewTab = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <div>
      <style>
        {`
          .react-pdf__Page__textContent {
            display: none;
          }
          .react-pdf__Page__annotations {
            display: none; /* Hide annotation layer */
          }
          .pdf-modal-content {
            max-height: 90vh; /* Adjusts the modal content to fit better */
            overflow-y: auto; /* Allows scrolling if needed */
            display: flex;
            justify-content: center;
            padding-bottom: 60px; /* Leave space for the controls */
            position: relative; /* Allows absolute positioning for controls */
          }
          .dialog-actions {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: white;
            z-index: 1;
            border-top: 1px solid #ddd;
            padding: 10px;
            display: flex;
            justify-content: space-between; /* Adjust buttons layout */
          }
          .dialog-actions.mobile {
            position: static; /* Use normal flow on mobile */
          }
        `}
      </style>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogContent className="pdf-modal-content">
          {!isMobile ? (
            <Document
              file={pdfUrl}
              onLoadSuccess={onLoadSuccess}
              renderTextLayer={false} // Hide text layer
              renderAnnotationLayer={false} // Disable annotations
            >
              <Page pageNumber={pageNumber} scale={1.5} /> {/* Adjust the scale as needed */}
            </Document>
          ) : (
            <StyledButton onClick={openInNewTab}>
              Open PDF in New Tab
            </StyledButton>
          )}
        </DialogContent>
        <DialogActions className={`dialog-actions ${isMobile ? "mobile" : ""}`}>
          {!isMobile && (
            <>
              <StyledButton onClick={prevPage} disabled={pageNumber === 1}>
                Previous
              </StyledButton>
              <StyledButton
                onClick={nextPage}
                disabled={pageNumber === numPages}
              >
                Next
              </StyledButton>
              <StyledButton onClick={handleDownload}>
                Download PDF
              </StyledButton>
            </>
          )}
          <StyledButton onClick={onClose}>Close</StyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PdfModal;
