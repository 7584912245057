import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { StyledGear } from "../Custom-Icons/CustomIcons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import PdfModal from "./PdfModal";

const Wrapper = styled("div")({
  width: "100%",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "2rem",
  opacity: "0.9",
  justifyContent: "start",
});

const CardCtn = styled("div")({
  display: "flex",
  flexDirection: "column",
  overflowY: "hidden",
  width: "70%",
  gap: "0.2rem",
  "@media (max-width: 700px)": {
    // minHeight: "70rem",
    scrollBehavior: "smooth",
    width: "100%",
  },
});

const ContentCard = styled(Accordion)({
  backgroundColor: "rgb(46, 48, 145)",
  width: "100%",
  borderBottom: "1px solid rgb(255, 242, 0)",
  "&:last-child": {
    borderBottom: "none",
  },
});

const ContentTitleCtn = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
});

const ContentCardTitle = styled(Typography)({
  padding: "0.3rem",
  textAlign: "center",
  fontWeight: "bold",
  color: "rgb(255, 242, 0)",
});

const ContentCardParaCtn = styled(AccordionDetails)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  background:
    "linear-gradient(to bottom, rgb(46, 48, 145), rgb(80, 83, 187, 0.5));",

  ul: {
    color: "yellow",
    width: "80%",
  },
  "&.Mui-expanded": {
    margin: "12px 0",
  },
}));

const ContentCardPara = styled(Typography)({
  color: "rgb(255, 242, 0)",
  textAlign: "left",
  fontWeight: "bold",
  maxWidth: "80%",
});

const TextCtn = styled(Typography)({
  maxWidth: "80%",
  textAlign: "center",
  fontWeight: "bold",
  marginBottom: "1rem",
  color: "rgb(46, 48, 145)",
  "&:h3": {
    fontSize: "1.3rem",
    fontWeight: "600",
    lineHeight: "1.4",
    letterSpacing: "0.5px",
    padding: "0 1rem",
    margin: "0 auto",
  },

  "@media (max-width: 600px)": {
    "&.h3": {
      fontSize: "1rem",
      padding: "0 0.5rem",
    },
  },
});

const StyledButton = styled(Button)({
  padding: "10px 20px",
  fontSize: "16px", 
  marginBottom: "10px",
  backgroundColor: "rgb(46, 48, 145)", 
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgb(38, 40, 125)", 
  },
});
const AboutUs = () => {
  const [expanded, setExpanded] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const pdfUrl = "/assets/Brochure-final.pdf";
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  return (
    <Wrapper>
      <Typography
        variant="h4"
        style={{
          width: "100%",
          textAlign: "center",
          padding: "1rem",
          color: "#FFFAF0",
          fontWeight: "bold",
          marginBottom: "1rem",
          backgroundColor: "#2E3091",
        }}
      >
        About Us{" "}
      </Typography>
      <StyledButton onClick={handleOpenModal}>Download Our Brochure</StyledButton>

      <PdfModal open={modalOpen} onClose={handleCloseModal} pdfUrl={pdfUrl} />
      <TextCtn>
        <h3>
          {" "}
          Beothuk Mechanical Corporation was founded in 2021 with the vision of
          delivering a high-quality specialized service. Utilizing the most
          cost-efficient approach to achieve top industry standards, thereby
          fostering economic growth and enhancing social wellbeing within the
          community. Sea-Can Modifications
        </h3>
      </TextCtn>
      <CardCtn>
        <ContentCard
          expanded={expanded === "mission"}
          onChange={handleChange("mission")}
        >
          <AccordionSummary expandIcon={<StyledGear fill="rgb(255, 242, 0)" />}>
            <ContentTitleCtn>
              <ContentCardTitle>Mission</ContentCardTitle>
            </ContentTitleCtn>
          </AccordionSummary>
          <ContentCardParaCtn>
            <ContentCardPara>
              Beothuk Mechanical Corporation [BMC] is committed to driving
              economic growth and innovation in our local community while
              continuously evolving to meet the dynamic needs of our clients and
              team through effective collaboration. We provide clients with high
              quality drill consumable products at cost effective prices, 15000
              SqFt of heated and non-heated warehousing space as well as a
              delivery service. We highly promote a safety conscious work
              environment. BMC is an equal opportunity corporation that
              encourages a diverse and inclusive environment.
            </ContentCardPara>
          </ContentCardParaCtn>
        </ContentCard>
        <ContentCard
          expanded={expanded === "values"}
          onChange={handleChange("values")}
        >
          <AccordionSummary expandIcon={<StyledGear fill="rgb(255, 242, 0)" />}>
            <ContentTitleCtn>
              <ContentCardTitle>Values</ContentCardTitle>
            </ContentTitleCtn>
          </AccordionSummary>
          <ContentCardParaCtn>
            <List>
              <ListItem>
                <ListItemText
                  primary="Professionalism"
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Safety"
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Integrity"
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Quality"
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Respect"
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
              </ListItem>
            </List>
          </ContentCardParaCtn>
        </ContentCard>
        <ContentCard
          expanded={expanded === "vision"}
          onChange={handleChange("vision")}
        >
          <AccordionSummary expandIcon={<StyledGear fill="rgb(255, 242, 0)" />}>
            <ContentTitleCtn>
              <ContentCardTitle>Vision</ContentCardTitle>
            </ContentTitleCtn>
          </AccordionSummary>
          <ContentCardParaCtn>
            <ContentCardPara>
              BMC is committed to becoming a leading supplier of drill
              consumables and just-in-time warehousing by leveraging safety,
              training and technology to ensure excellence and reliability in
              its services
            </ContentCardPara>
          </ContentCardParaCtn>
        </ContentCard>
      </CardCtn>
    </Wrapper>
  );
};
export default AboutUs;
